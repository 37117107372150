"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: true,
    },
    appName: 'Kanon Digital Collection',
    organizationId: 'shizQkBFCglpj5ukxE1r',
    version: '1.1',
    ionicAppId: '41f91b77',
    applicationKey: 'kanonshizaki',
    applicationType: 'standalone',
    artistId: '3jDMhZ97gzMyd5PXOcPmWy2A4HI2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.kanonshizaki',
        appId: '6448008300',
    },
    android: {
        // kanonshizakiは限定公開で作成してしまったため、kanonshizaki2を正式なバンドルとして使用
        bundleId: 'com.utoniq.kanonshizaki2',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'myProfile'],
    },
    deeplinkUrl: 'https://kanonshizaki.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3564140',
    storagePath: 'kanonshizaki',
    dynamicLinkPrefix: 'kanonshizaki',
    deeplinkProd: 'kanonshizaki.utoniq.com',
};
exports.default = appConfig;
